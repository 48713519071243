import { createContext, useState } from "react";

export const LoaderContext = createContext();

export const LoaderContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [appConfigs, setAppConfigs] = useState({});

  return (
    <LoaderContext.Provider
      value={{ loading, setLoading, appConfigs, setAppConfigs }}
    >
      {children}
    </LoaderContext.Provider>
  );
};
