// import Routes from "./routes";
import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import { LoaderContext } from "./context/loadingContext";
import { useContext } from "react";
import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getStorage } from "firebase/storage";
import "firebase/messaging";
import "firebase/auth";
// import MyRoutes from "./routes";

const MyRoutes = React.lazy(() => import("./routes"));
const Loader = React.lazy(() => import("./component/loader"));

export let firebaseConfig = {};
export let collections = {
  charity: "charities",
  survey: "surveys",
  banner: "appconfig",
  category: "categories",
  submissions: "submissions",
  clients: "clients",
  users: "users",
  offers: "offers",
  admin: "admin",
  messageRequests: "messageRequests",
};
export let documentIds = {
  bannerDocId: "currentConfig",
  categoryDocId: "categoriesdata",
};

export let app;
export let auth;
export let db;
export let storageGet;

export default function App() {

  let firebaseConfig = {
    apiKey: "AIzaSyBFvByrzW_hmo91xOaZcFnpJW_9Ybbs4h4",
    authDomain: "good-13f28.firebaseapp.com",
    projectId: "good-13f28",
    storageBucket: "good-13f28.appspot.com",
    messagingSenderId: "519294074669",
    appId: "1:519294074669:web:b620326e6466f8dfabdda4",
    measurementId: "G-3N8KKF2PN5"
  };

  app = initializeApp(firebaseConfig);
  auth = getAuth(app);
  db = getFirestore(app);
  storageGet = getStorage(app);

/*  useEffect(() => {
    axios
      .get(
        "https://us-central1-survey-4-good-dev.cloudfunctions.net/getConfiguration"
      )
      .then((response) => {
        let firebaseConfig = {
          apiKey: response?.data?.api_key,
          authDomain: response?.data?.auth_domain,
          databaseURL: response?.data?.database_url,
          projectId: response?.data?.project_id,
          storageBucket: response?.data?.storage_bucket,
          messagingSenderId: response?.data?.messaging_sender_id,
          appId: response?.data?.app_id,
          measurementId: response?.data?.measurement_id,
        };
        app = initializeApp(firebaseConfig);
        auth = getAuth(app);
        db = getFirestore(app);
        storageGet = getStorage(app);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);  */

  return (
    <>
      <Loader />
      <ToastContainer />
      <Suspense fallback={<div>Loading...</div>}>
        <MyRoutes />
      </Suspense>
    </>
  );
}
